/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { observer } from 'mobx-react';
// @ts-ignore
import featuredImage from '../../resources/images/liinakonkatu.png';

interface NewResidentChecklist {
}

const NewResidentChecklistPage: FC<NewResidentChecklist> = observer(() => {
  return (
    <ContentPageLayout slug="new-resident-checklist" featuredImageFile={featuredImage} />
  );
});

export default NewResidentChecklistPage;
